import { createGlobalState } from '@vueuse/core'
import { getDatabase, ref } from "firebase/database";
import { reactive, ref as refVue } from 'vue'
import { getFirestore, doc, setDoc, updateDoc, deleteField, collection, getDocs, QuerySnapshot , onSnapshot, type Unsubscribe} from "firebase/firestore";


export const useFirebase = createGlobalState(
    () => {
        // state
        const storeId = refVue(null)

        // getters
        // const doubleCount = computed(() => count.value * 2)

        // actions
        function setStoreId(id) {
            storeId.value = id;
        }


        function jobchainRef() {
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/jobchain`);
        }

        function itemsRef(){
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/items`);
        }

      

        function summaryRef(){
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/summary`);
        }

        function tablesRef(){
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/tables`);
        }

        function tableStateRef() { 
              
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/tableState`);
        }

        function tableMetaRef() { 
              
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/tableMeta`);
        }

        function tableMoneyStateRef() { 
              
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/tableMoneyState`);
        }

        function receiptsRef(yyyymm :string){
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/receipts/${yyyymm}`);
        }

        function productsRef(){
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/products`);
        }

        function handshakeRef(){
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/handshake`);
        }

        function jobRequestRef() {

            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/jobRequest`);
        }


        function printPreviewRequestRef() {

            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/requestPrintPreview`);
        }        

         
        function settingsRef() {

            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/settings`);
        }

        function employeesRef() {

            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/employees`);
        }

        function qrcodeOrdersRef() { 
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/qrcode_orders`);
        }


        function shopNameRef() { 
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/_name`);
        }

        function onlineRef(computerRef :string) { 
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/online/${computerRef}`);

        }


        function _createRef(key: string) { 
            const db = getDatabase();

            return ref(db, `stores/${storeId.value}/${key}`);
        }

        function webAppReloadRef() {
          const db = getDatabase();

          return ref(db, `app/web/forceReload`);
      }

        return {
            storeId,
            setStoreId,
            getStoreId: () => storeId.value,
            jobchainRef,
            itemsRef,
            receiptsRef,
         
            summaryRef,
            tablesRef,
            tableStateRef,
            tableMetaRef,
            tableMoneyStateRef,
            productsRef,
            handshakeRef,
            jobRequestRef,
            settingsRef,
            employeesRef,
            qrcodeOrdersRef,
            shopNameRef,
            onlineRef ,
            _createRef,
            webAppReloadRef,
            printPreviewRequestRef
        }
    }
)

export const useFirebaseAnalytic = createGlobalState(
    () => {
        // state
        

        function activeStoresRef() {
            const db = getDatabase();

            return ref(db, `analytic/activeStores`);
        }

        
        return { 
            activeStoresRef
        }

 
    }
)


export const useFirebaseFirestore = createGlobalState(

    () => {
        // state
        const storeId = refVue(null)


        return {
            setStoreId,
            getStoreid: () => storeId.value,
            collectionRef,
            collectionDocIdRef,
            subCollectionRef,
            subCollectionDocIdRef,            
            addDayJobs,
            voidItemCollectionRef,          
            soldoutProductCollectionRef,   
            changeProductPriceCollectionRef, 
            openMenuCollectionRef,
            collectionBetaWhiteList,   
            pairingTokenRef,
            onSnapshotCollection
        }
        function setStoreId(id) {
            storeId.value = id;
        }

        function collectionBetaWhiteList() {
            const db = getFirestore();
            const docRef = doc(db, "beta_whitelist", storeId.value);
            return docRef;
        } 


        function addDayJobs(docId, data) {
            const db = getFirestore();
            const docRef = doc(db, "stores", storeId.value, 'jobs', docId);
            return setDoc(docRef, data);
        }


        function voidItemCollectionRef() {
            const db = getFirestore();
            return collection(db, "stores", storeId.value, 'voidItem');  
            
        }

        function openMenuCollectionRef() {
            const db = getFirestore();
            return collection(db, "stores", storeId.value, 'openMenu');              
        }        


        function soldoutProductCollectionRef() {
            const db = getFirestore();
            return doc(db, "stores", storeId.value, 'appState','soldout');          
        }

        function changeProductPriceCollectionRef() {
            const db = getFirestore();
            return collection(db, "stores", storeId.value, 'changeProductPrice');  
            
        }
        
        function pairingTokenRef( tokenId ) {
            const db = getFirestore();
            const docRef = doc(db, "pairing_tokens", tokenId);
            return docRef;
        }

            

        function collectionRef(collectionName) {
            const db = getFirestore();
            const collectionRef  = collection(db, collectionName  );
            return collectionRef;
        }

        function collectionDocIdRef(collectionName, docId) {
            const db = getFirestore();
            const docRef = doc(db, collectionName, docId);
            return docRef;
        }

        function subCollectionRef (subCollectionName : string) {
            const db = getFirestore();
            const collectionRef  = collection(db, `stores/${storeId.value}/${subCollectionName}`);
            return collectionRef;
        }


        function subCollectionDocIdRef (subCollectionName : string, docId : string) {
            const db = getFirestore();
            const docRef = doc(db, `stores/${storeId.value}/${subCollectionName}`, docId);
            return docRef;
        }
        

        function onSnapshotCollection (collectionName:string, id: string, callback: (payment:any) => void): Unsubscribe {
            const db = getFirestore();
            const docRef = doc(db, collectionName, id)
         
            const unsub = onSnapshot(docRef, (doc) => {
              const data = doc.data() 
              callback(data)
            })
        
            return unsub
          } 

    }
)
 