import { useAuthStore } from "./auth";
import dayjs from "dayjs";
import { onDisconnect, set } from "firebase/database";
import { useFirebase, useFirebaseFirestore } from "../composables/useFirebase";
import { useRunningMode } from "../composables/useRunningMode";
import dataservice from "../services/dataservice";
import DataService from "../services/dataservice/dataservice.base";
import { setDoc, query, onSnapshot, where , doc, getDoc } from "firebase/firestore";
import { setCookie } from "../../core/utils/cookie";

// auth ,  restore, fetch, watch , ready
export const useShopStore = defineStore("shop", {
  state: () => {
    return {
      storeId: "",
      // name: '',
      // package: '',
      // storeList: [],
      expireDate:null,
    };
  },
  getters: {
    getExpiredDate(state) {       
      if(!state.expireDate){
        return null
      }

      let addDayToExpireDate = dayjs(state.expireDate).add(31, 'hours'); //  1 day and 7 hours     
      return addDayToExpireDate
    },
    displayExpiredDate(state){
      if(!state.expireDate){
        return null
      }

      let addDayToExpireDate = dayjs(state.expireDate); //  1 day and 7 hours
      return addDayToExpireDate.format("DD/MM/YYYY")
    }
  },
  actions: {
    async init() {
      const authStore = useAuthStore();
      const uid = authStore.uid;

      // get storeId by uid
      let res = await dataservice.users.getById(uid);     

      this.storeId = res.data[0];
      this.registerModule(this.storeId);
      this.getStoreData(res.data);
      this.getStoreLastUpdate(this.storeId)

      this.trackCashierOnline();
    },
    async fetchBetaWhiteLists() {            
      console.log("starting beta...")

      
      const currentUrl = useRequestURL()


      // check is localhost
      if(currentUrl.hostname == "localhost"){
        return
      }

      
      let firestore = useFirebaseFirestore()    
      let collectionRef = firestore.collectionBetaWhiteList()   
      
      const isBetaSite = currentUrl.hostname == "app2.xtable.co"
      




      const docSnap = await getDoc(collectionRef);         
      if (docSnap.exists() && !isBetaSite) {
          const authStore = useAuthStore();
          const uid = authStore.uid
        
          const betaData = {
              isBeta:true,
              uid,
              storeId:this.storeId
          }
          setCookie("xtable", JSON.stringify(betaData), 30 ,".xtable.co")   
         // window.location.href = `localhost:3000`

          window.location.href = `https://app2.xtable.co`
          throw new Error()
          
      }
      

  },    
    registerModule(storeId) {
      // set firebase path
      const fb = useFirebase();
      fb.setStoreId(storeId);

      const fbFirestore = useFirebaseFirestore();
      fbFirestore.setStoreId(storeId);

      // set dataservice receipt path
      // dataservice.receipts.setStoreId(storeId)
      // dataservice.jobs.setStoreId(storeId)
      // dataservice.products.setStoreId(storeId)
      // dataservice.inventories.setStoreId(storeId)
      // dataservice.store.setStoreId(storeId)
      // dataservice.users.setStoreId(storeId)
      // dataservice.settings.setStoreId(storeId)
      // dataservice.employees.setStoreId(storeId)

      for (const key in dataservice) {
        dataservice[key].setStoreId(storeId);
      }
    },
    async getStoreData(storeId) {
      try {    
        const res = await dataservice.store.getById(storeId); 
        let expireDate = res?.data?.expireDate ;     
        //let expireDate = res?.data?.expireAt ;     
       
        this.$patch({
          ...res.data,
        });

        this.expireDate = expireDate
      } catch (error) {
        console.log(error);
      }
    },

    async getStoreLastUpdate(storeId:string) {
      try {    
        const res = await new DataService(`/stores/${storeId}/lastUpdate`).getAll()
        console.log("Lasted Update... ", res.data)        
      } catch (error) {
        console.log(error);
      }
    },

    trackCashierOnline() {
      const authStore = useAuthStore();
      let computerUUid = authStore.computerUuid;

      // check if main cahier

      const onlineRef = useFirebase().onlineRef(computerUUid);

      // onDisconnect(onlineRef).set(null)

      let runMode = useRunningMode().getMode();

      set(onlineRef, {
        runMode: runMode,
        timestamp: dayjs().format(),
        computerUUid,
      });

      const updateTimeFunc = () => {
        console.log("updateTimeFunc");
        set(onlineRef, {
          runMode: runMode,
          timestamp: dayjs().format(),
          computerUUid,
        });
      };

      // update every 1 min
      setInterval(updateTimeFunc, 1000 * 60);
    },
  },
});
