import { PiniaPluginContext } from "pinia";

function IsBetaPlugin({ store }: PiniaPluginContext) {
  if (store.$id == "pos") {
     
    if (window.location.href.includes("app2")) {
      store.isBeta = true;
    } else {
      store.isBeta = false;
    }
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(IsBetaPlugin);
});
