import dayjs from "dayjs";
import { useShopStore } from "../modules/core/store/shop";

export default defineNuxtRouteMiddleware((to, from) => {
  const shopStore = useShopStore();
  const expireDate = shopStore.expireDate;
  const addDayToExpireDate = shopStore.getExpiredDate; // 1 day and 7 hours

  const allowedPaths = ["/topup/promptpay"];

  console.log("expireDate===>", expireDate);

  if (allowedPaths.some((path) => to.path.startsWith(path))) {
    return;
  }

  if (to.path.startsWith("/expired")) {
    return;
  }

  console.log("addDayToExpireDate", addDayToExpireDate);

  const currentDate = dayjs();
  console.log(
    "dayjs(addDayToExpireDate).isBefore(currentDate)==>",
    dayjs(addDayToExpireDate).isBefore(currentDate)
  );

  if (expireDate && dayjs(addDayToExpireDate).isBefore(currentDate)) {
    console.log("Expire date!!!");
    if (!to.path.startsWith("/expired")) {
      return navigateTo(`/expired?storeId=${shopStore.storeId}`);
    }
  }
});
