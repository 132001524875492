import revive_payload_client_4sVQNw7RlN from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_j7wLaRg9eH from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import slideovers_LDumGYo2KH from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import thermalprinter_plugin_6fX8PnkLVV from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/thermalPrinter/plugins/thermalprinter.plugin.ts";
import isBeta_plugin_fuUgtCDJ5S from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/isBeta.plugin.ts";
import requestPrintPreview_CGpoOIPR11 from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/requestPrintPreview.ts";
import openMenu_plugin_RCIly9bUFM from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/openMenu.plugin.ts";
import changeProductPrice_plugin_rOvRwtFMWQ from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/changeProductPrice.plugin.ts";
import voidItem_cloud_plugin_kKIh13sCEP from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/voidItem.cloud.plugin.ts";
import tablesState_plugin_tNd8wRR7gg from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/tablesState.plugin.ts";
import tableMeta_plugin_8VROcdoW66 from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/tableMeta.plugin.ts";
import receipts_plugin_LaAXw2bACT from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/receipts.plugin.ts";
import mobile_items_plugin_HjsOAO8dQp from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/mobile.items.plugin.ts";
import jobs_local_plugin_h4quKfxeLF from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/jobs.local.plugin.ts";
import jobs_cloud_plugin_ooLmwX3U7i from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/pos/plugins/jobs.cloud.plugin.ts";
import sentry_client_uZGTEkpV93 from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/core/plugins/sentry.client.ts";
import forceReload_client_FgTklIvb95 from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/core/plugins/forceReload.client.ts";
import timechange_client_tvDgQK9bKn from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/modules/core/plugins/timechange.client.ts";
import chunk_reload_client_UciE0i6zes from "/Users/niceloop/Desktop/Niceloop/nuxt3-pos-v3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_j7wLaRg9eH,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  thermalprinter_plugin_6fX8PnkLVV,
  isBeta_plugin_fuUgtCDJ5S,
  requestPrintPreview_CGpoOIPR11,
  openMenu_plugin_RCIly9bUFM,
  changeProductPrice_plugin_rOvRwtFMWQ,
  voidItem_cloud_plugin_kKIh13sCEP,
  tablesState_plugin_tNd8wRR7gg,
  tableMeta_plugin_8VROcdoW66,
  receipts_plugin_LaAXw2bACT,
  mobile_items_plugin_HjsOAO8dQp,
  jobs_local_plugin_h4quKfxeLF,
  jobs_cloud_plugin_ooLmwX3U7i,
  sentry_client_uZGTEkpV93,
  forceReload_client_FgTklIvb95,
  timechange_client_tvDgQK9bKn,
  chunk_reload_client_UciE0i6zes
]