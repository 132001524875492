export const BASE_URL = {
    core:{
     // dev: "https://ixkk2e07of.execute-api.ap-southeast-1.amazonaws.com",
        dev: "https://99pd3xjl10.execute-api.ap-southeast-1.amazonaws.com", // new url
        prod: "https://99pd3xjl10.execute-api.ap-southeast-1.amazonaws.com", // new url
    },
    report:{
        prod: "https://prod",
        dev: "https://dev",
    }
}